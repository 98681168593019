*, :before, :after {
  box-sizing: border-box;
}

* {
  min-height: 0;
  min-width: 0;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: open_sans, Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6, .semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

svg.icon {
  fill: currentColor;
}

.backbutton {
  color: #fff;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
  transition: all .2s ease-in-out;
  display: flex;
}

.backbutton:hover {
  opacity: .7;
}

.indicator {
  z-index: 1;
  position: relative;
}

@-webkit-keyframes show {
  100% {
    opacity: 1;
  }
}

@keyframes show {
  100% {
    opacity: 1;
  }
}

.flex {
  display: flex;
}

.justify-content-end {
  justify-content: flex-end;
}

@font-face {
  font-family: open_sans;
  src: url("opensans-regular-webfont.7aee7f67.woff2") format("woff2"), url("opensans-regular-webfont.9c8f86e3.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: open_sans;
  src: url("opensans-semibold-webfont.c57a7df7.woff2") format("woff2"), url("opensans-semibold-webfont.bed514b4.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: open_sans;
  src: url("opensans-bold-webfont.cd911c14.woff2") format("woff2"), url("opensans-bold-webfont.020eebe5.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: open_sans;
  src: url("opensans-italic-webfont.91857ce4.woff2") format("woff2"), url("opensans-italic-webfont.cc887125.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: open_sans;
  src: url("opensans-bolditalic-webfont.6fe0d0f6.woff2") format("woff2"), url("opensans-bolditalic-webfont.c3af6589.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: open_sans;
  src: url("opensans-semibolditalic-webfont.c277deb8.woff2") format("woff2"), url("opensans-semibolditalic-webfont.dc0801dd.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: appstore;
  src: url("appstore.154eece7.woff2") format("woff2"), url("appstore.d1660f1f.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  speak: never;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: appstore !important;
}

.icon-chevron-left:before {
  content: "";
}

.icon-chevron-up:before {
  content: "";
}

.icon-chevron-down:before {
  content: "";
}

.icon-chevron-right:before {
  content: "";
}

.icon-user-icon:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-cross:before {
  content: "";
}

.icon-tick:before {
  content: "";
}

.icon-download:before {
  content: "";
}

button {
  cursor: pointer;
  border: none;
}

button:focus {
  outline: none;
}

button:focus-visible, button.focus-visible {
  outline: 2px solid #000;
}

button.disabled, button:disabled {
  opacity: .5;
  cursor: default;
  outline: none;
}

button.disabled:focus, button:disabled:focus {
  outline: none;
}

.button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  background: #007bb3;
  border-radius: 10px;
  align-items: center;
  margin: 0;
  padding: 10px 20px;
  font-family: open_sans, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.8em;
  text-decoration: none;
  transition: background-color .2s ease-in-out, color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;
  display: inline-flex;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .21);
}

.button-primary {
  background: #007bb3;
  border: 1px solid #007bb3;
}

.button-primary:not(.disabled):not(:disabled):hover {
  color: #fff;
  background: #0097dc;
  border-color: #0097dc;
}

.button-primary:not(.disabled):not(:disabled):active {
  background: #005880;
  border-color: #005880;
}

.button-primary.disabled, .button-primary:disabled {
  opacity: .5;
  color: rgba(255, 255, 255, .5);
}

.button-secondary {
  background: #004666;
  border: 1px solid #004666;
}

.button-secondary:not(.disabled):not(:disabled):hover {
  color: #fff;
  background: #00628f;
  border-color: #00628f;
}

.button-secondary:not(.disabled):not(:disabled):active {
  background: #002333;
  border-color: #002333;
}

.button-secondary.disabled, .button-secondary:disabled {
  opacity: .5;
  color: rgba(255, 255, 255, .5);
}

.button-apps {
  background: #007bb3;
  border: 1px solid #007bb3;
}

.button-apps:not(.disabled):not(:disabled):hover {
  color: #fff;
  background: #0097dc;
  border-color: #0097dc;
}

.button-apps:not(.disabled):not(:disabled):active {
  background: #005880;
  border-color: #005880;
}

.button-apps.disabled, .button-apps:disabled {
  opacity: .5;
  color: rgba(255, 255, 255, .5);
}

.button-extensions {
  background: #5c9b0d;
  border: 1px solid #5c9b0d;
}

.button-extensions:not(.disabled):not(:disabled):hover {
  color: #fff;
  background: #72c110;
  border-color: #72c110;
}

.button-extensions:not(.disabled):not(:disabled):active {
  background: #406c09;
  border-color: #406c09;
}

.button-extensions.disabled, .button-extensions:disabled {
  opacity: .5;
  color: rgba(255, 255, 255, .5);
}

.button-blocks {
  background: #f70;
  border: 1px solid #f70;
}

.button-blocks:not(.disabled):not(:disabled):hover {
  color: #fff;
  background: #ff8d29;
  border-color: #ff8d29;
}

.button-blocks:not(.disabled):not(:disabled):active {
  background: #cc5f00;
  border-color: #cc5f00;
}

.button-blocks.disabled, .button-blocks:disabled {
  opacity: .5;
  color: rgba(255, 255, 255, .5);
}

.button-blank {
  background: none;
  border-color: rgba(0, 0, 0, 0);
}

.button-small {
  padding: .6rem .8rem;
  font-size: 14px;
}

.button-icon {
  gap: 10px;
}

.button-icon .icon {
  flex: none;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0);
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
  transition: max-width .2s ease-in-out;
  display: flex;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1300px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1580px;
  }
}

.header {
  width: 100%;
  height: 75px;
}

.header img {
  display: block;
}

.header .h1 {
  font-size: 24px;
  font-weight: 600;
}

@media (min-width: 480px) {
  .header .h1 {
    font-size: 26px;
  }
}

.header .environments {
  align-items: center;
  gap: 10px;
  display: flex;
}

@media (max-width: 480px) {
  .header .environments {
    display: none;
  }
}

.header .environments input {
  color: #fff;
  background: rgba(255, 255, 255, .1);
  border: 1px solid rgba(255, 255, 255, .4);
  border-radius: 4px;
  outline: none;
  padding: 7px;
  transition: all .2s ease-in-out;
}

.header .environments input:hover {
  background: rgba(255, 255, 255, .2);
  border-color: rgba(255, 255, 255, .6);
}

.header .environments input:focus {
  background: rgba(255, 255, 255, .2);
  border-color: #fff;
}

.header .environments input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, .5);
}

.header .environments input::placeholder {
  color: rgba(255, 255, 255, .5);
}

.header .environments input[aria-invalid="true"] {
  border-color: #b22222;
}

.header .environments .inputWrap {
  flex-direction: column;
  display: flex;
  position: relative;
}

.header .environments .inputWrap .error {
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  left: 5px;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.header .environments .infoWrap {
  align-items: center;
  display: flex;
  position: relative;
}

.header .environments .infoWrap .info {
  cursor: pointer;
  color: #fff;
  height: 23px;
  background: rgba(255, 255, 255, .1);
  border: 1px solid rgba(255, 255, 255, .4);
  border-radius: 3px;
  align-items: center;
  padding: 0 7px;
  font-size: 15px;
  transition: all .2s ease-in-out;
  display: flex;
}

.header .environments .infoWrap .info:hover, .header .environments .infoWrap .info:active {
  background: rgba(255, 255, 255, .2);
  border-color: rgba(255, 255, 255, .6);
}

.header .environments .infoWrap .info-tooltip {
  width: -webkit-max-content;
  width: max-content;
  max-width: 250px;
  color: #004666;
  visibility: hidden;
  opacity: 1;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .16);
  margin-top: 15px;
  padding: 0 12px;
  font-size: 13px;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .2);
}

.header .environments .infoWrap .info-tooltip:before, .header .environments .infoWrap .info-tooltip:after {
  pointer-events: none;
  content: " ";
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.header .environments .infoWrap .info-tooltip:after {
  border: 7px solid rgba(0, 0, 0, 0);
  border-bottom-color: #f9f9f9;
  top: -14px;
}

.header .environments .infoWrap .info-tooltip:before {
  border: 8px solid rgba(0, 0, 0, 0);
  border-bottom-color: #cdcdcd;
  top: -16px;
}

.header .environments .infoWrap .info-tooltip p {
  margin: 8px 0;
}

.header .environments .infoWrap .info-tooltip span {
  color: #007bb3;
  font-weight: 700;
}

.header .environments .infoWrap .info:hover + .info-tooltip {
  visibility: visible;
  opacity: 1;
  margin-top: 10px;
}

.header .inner {
  width: 100%;
  height: inherit;
  -webkit-filter: drop-shadow(0 3px 6px rgba(0, 0, 0, .16));
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, .16));
  color: #fff;
  z-index: 4;
  background: linear-gradient(90deg, #0081bb 0%, #00415e 100%);
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 480px) {
  .header .logo {
    width: 150px;
  }
}

.header .logo img {
  max-width: 100%;
}

.header .container {
  height: 100%;
  max-width: none;
  justify-content: space-between;
  align-items: center;
}

.header .column-right {
  align-items: center;
  gap: 20px;
  display: flex;
}

@media (min-width: 400px) {
  .header .column-right {
    gap: 50px;
  }
}

.header div.icon {
  width: 40px;
  height: 40px;
  position: relative;
}

.header div.icon .icon {
  position: absolute;
  top: 0;
  left: 0;
}

.header .profile-menu {
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.header .profile-menu a {
  cursor: pointer;
}

.header .profile-menu .dropdown-menu {
  color: #004666;
  background: rgba(255, 255, 255, .9);
  border: 1px solid rgba(60, 60, 60, .18);
  border-radius: 10px;
  font-size: 14px;
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .32);
}

.header .profile-menu .dropdown-menu.show {
  display: block;
}

.header .profile-menu .dropdown-menu a {
  color: inherit;
  text-decoration: none;
}

.header .profile-menu .dropdown-menu-items {
  flex-direction: column;
  display: flex;
}

.header .profile-menu .dropdown-menu-items a {
  padding: 10px 20px;
  line-height: normal;
}

.header .profile-menu .profile-image {
  width: 48px;
  height: 48px;
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 100%;
  flex: none;
  overflow: hidden;
  box-shadow: 0 0 6px rgba(0, 0, 0, .16);
}

.header .profile-menu .profile-image:hover .profile-picture {
  -webkit-transform: scale(1.07);
  transform: scale(1.07);
}

.header .profile-menu .profile-picture {
  width: 44px;
  height: 44px;
  object-fit: cover;
  transition: all .2s ease-in-out;
}

.header .profile-menu .username {
  white-space: nowrap;
  font-weight: 600;
}

.header button.sign-in {
  width: 40px;
  height: 40px;
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 40px;
  transition: all .2s ease-in-out;
}

.header button.sign-in:hover {
  color: #007bb3;
}

.intro-block {
  color: #fff;
  background: linear-gradient(90deg, #009be1 0%, #004e71 100%);
  position: relative;
  overflow: hidden;
}

.intro-block .decoration {
  width: 100vw;
  height: 50vw;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  background: linear-gradient(90deg, #19a0dc 0%, #0076ab 100%);
  position: absolute;
  top: -50vw;
  left: 30%;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

@media (min-width: 768px) {
  .intro-block .decoration {
    left: 50%;
  }
}

.intro-block .container {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
  position: relative;
}

.intro-block .container .column-left {
  order: 2;
}

.intro-block .container .column-right {
  order: 1;
}

@media (min-width: 768px) {
  .intro-block .container {
    flex-direction: row;
    padding-bottom: 0;
  }

  .intro-block .container .column-left {
    width: 55%;
    order: 1;
  }

  .intro-block .container .column-right {
    width: 60%;
    order: 2;
  }
}

@media (min-width: 1200px) {
  .intro-block .container .column-left, .intro-block .container .column-right {
    width: 50%;
  }
}

.intro-block .container .column-right img {
  max-width: 100%;
  display: block;
}

.intro-block h1 {
  font-size: max(26px, min(3vw, 35px));
  line-height: 1.2;
}

.card {
  background: rgba(255, 255, 255, .75);
  border: 1px solid rgba(60, 60, 60, .18);
  border-radius: 10px;
  transition: background-color .2s ease-in-out, color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .32);
}

.tag {
  color: #004666;
  background: #fff;
  border: 1px solid #007bb3;
  border-radius: 6px;
  flex: none;
  align-items: center;
  gap: 5px;
  padding: 3px 20px;
  font-size: 14px;
  display: flex;
}

.tag.has-icon {
  padding: 3px 10px;
}

.tag.has-icon .dot {
  width: 6px;
  height: 6px;
  background: currentColor;
  border-radius: 100%;
}

.status {
  align-items: center;
  gap: 5px;
  font-size: 12px;
  display: flex;
}

.status .dot {
  width: 6px;
  height: 6px;
  background: currentColor;
  border-radius: 100%;
}

.cards-block {
  padding: 40px 0;
  position: relative;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .cards-block {
    padding: 80px 0;
  }
}

.cards-block .container {
  flex-direction: column;
  gap: 25px;
}

@media (min-width: 1200px) {
  .cards-block .container {
    flex-direction: row;
    gap: 5vw;
  }

  .cards-block .column-left {
    width: 33%;
    margin-right: 2vw;
  }
}

.cards-block .column-left h2 {
  margin: 0 0 .5em;
  font-size: max(18px, min(3vw, 30px));
}

@media (min-width: 1200px) {
  .cards-block .column-left h2 {
    margin-top: 1em;
  }
}

.cards-block .column-left p {
  margin-top: 0;
}

.cards-block .column-right {
  position: relative;
}

@media (min-width: 1200px) {
  .cards-block .column-right {
    width: 66%;
    flex-grow: 1;
    justify-content: flex-start;
  }
}

.cards-block .column-right .indicator {
  position: unset;
  pointer-events: none;
  grid-column: 1 / 4;
  align-items: flex-start;
  padding: 0;
}

.cards-block .column-right .indicator .loading-error {
  flex: none;
}

@media (min-width: 1200px) {
  .cards-block .column-right .indicator {
    padding-left: 0;
    padding-right: 0;
    position: absolute;
  }

  .cards-block .column-right .indicator .indicator-inner {
    margin-top: 130px;
  }
}

.cards-block .cards {
  flex-flow: column wrap;
  gap: 25px;
  display: flex;
}

@media (min-width: 700px) {
  .cards-block .cards {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    display: grid;
  }
}

@media (min-width: 1200px) {
  .cards-block .cards {
    gap: 35px;
  }
}

.cards-block .cards-list {
  gap: 10px;
  margin: 20px 0 0;
  display: flex;
  flex-direction: column !important;
}

.cards-block .cards-list .card {
  flex-direction: row;
  flex: none;
  padding: 10px 20px;
}

.cards-block .cards-list .card-top {
  flex-direction: row;
  padding-top: 0;
}

.cards-block .cards-list .card-top .image {
  height: 60px;
  width: 100px;
  margin-right: 20px;
  padding: 0;
}

.cards-block .cards-list .card-top p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 13px;
}

.cards-block .cards-list .card-top p:empty {
  display: none;
}

.cards-block .cards-list .card-top h3 {
  margin: 0;
  font-size: 16px;
}

.cards-block .cards-list .card-top p, .cards-block .cards-list .card-top h3 {
  text-align: left;
}

.cards-block .cards-list .card-bottom {
  justify-content: center;
  padding-top: 0;
}

.cards-block .cards-list .card-bottom .tag {
  align-self: flex-end;
}

.cards-block .card {
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  text-decoration: none;
  display: flex;
}

@media (min-width: 700px) {
  .cards-block .card {
    flex-grow: 1;
    flex-basis: 0;
  }
}

.cards-block .card:hover {
  background: rgba(255, 255, 255, .9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, .4);
}

.cards-block .card:hover img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.cards-block .card-top {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (min-width: 700px) {
  .cards-block .card-top {
    padding-top: 40px;
  }
}

.cards-block .card-top h3 {
  text-align: center;
  color: #007bb3;
  margin-top: 25px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2em;
}

.cards-block .card-top p {
  text-align: center;
  color: #004666;
  margin: 5px 0 1em;
  font-weight: 600;
}

.cards-block .card-bottom {
  flex-direction: column;
  padding-top: 20px;
  display: flex;
}

.cards-block .card-bottom .tag {
  align-self: flex-start;
}

.cards-block .card-bottom .status {
  margin: 5px 0 -10px;
}

.cards-block .card img {
  transition: all .2s ease-in-out;
}

.cards-block .card .image {
  height: 80px;
  padding: 0 20px;
}

.cards-block .card .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cards-block .overlay {
  width: 100%;
  height: 100%;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.cards-block .background {
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: .4;
  background-repeat: no-repeat;
  background-size: 60vw;
  position: absolute;
  top: 0;
  left: 0;
}

.cards-block .container {
  z-index: 2;
  position: relative;
}

.cards-block.style-apps {
  background: linear-gradient(90deg, #009be1 0%, #004e71 100%);
}

.cards-block.style-apps .cards {
  flex-direction: column-reverse;
}

@media (min-width: 700px) {
  .cards-block.style-apps .cards .card:nth-child(1) {
    grid-row-start: 1;
    grid-column-start: 3;
  }

  .cards-block.style-apps .cards .card:nth-child(2) {
    grid-row-start: 1;
    grid-column-start: 1;
  }

  .cards-block.style-apps .cards .card:nth-child(3) {
    grid-row-start: 1;
    grid-column-start: 2;
  }

  .cards-block.style-apps.cards-block-list .cards .card:nth-child(1) {
    grid-row-start: 1;
    grid-column-start: 3;
  }

  .cards-block.style-apps.cards-block-list .cards .card:nth-child(2) {
    grid-row-start: 1;
    grid-column-start: 2;
  }

  .cards-block.style-apps.cards-block-list .cards .card:nth-child(3) {
    grid-row-start: 1;
    grid-column-start: 1;
  }
}

.cards-block.style-apps .background {
  background-image: url("map-bg-right.2cb9aade.svg");
  background-position: 100% 100%;
}

.cards-block.style-apps .column-left {
  color: #fff;
}

.cards-block.style-apps .card-top p {
  color: #004666;
}

.cards-block.style-apps .card-top h3 {
  color: #007bb3;
}

.cards-block.style-apps .tag {
  color: #007bb3;
  border-color: #007bb3;
}

.cards-block.style-apps .status {
  color: #007bb3;
}

.cards-block.style-apps .indicator {
  color: #fff;
}

.cards-block.style-extensions {
  background: linear-gradient(90deg, #b5db85 0%, #dceec5 100%);
  border: 12px solid #fff;
  border-left: none;
  border-right: none;
}

.cards-block.style-extensions .background {
  background-image: url("map-bg-left.9fef46ec.svg");
  background-position: 0 100%;
}

.cards-block.style-extensions .column-left {
  color: #427700;
}

.cards-block.style-extensions .card-top p {
  color: #2a4b00;
}

.cards-block.style-extensions .card-top h3 {
  color: #5c9b0d;
}

.cards-block.style-extensions .tag {
  color: #243d05;
  border-color: #5c9b0d;
}

.cards-block.style-extensions .status {
  color: #5c9b0d;
}

.cards-block.style-extensions .indicator {
  color: #427700;
}

.cards-block.style-blocks {
  background: linear-gradient(90deg, #feebce 0%, #ffb64b 100%);
}

.cards-block.style-blocks .background {
  background-image: url("map-bg-right.2cb9aade.svg");
  background-position: 100% 100%;
}

.cards-block.style-blocks .column-left {
  color: #ce6000;
}

.cards-block.style-blocks .card-top p {
  color: #823d00;
}

.cards-block.style-blocks .card-top h3 {
  color: #f70;
  margin-bottom: 20px;
}

.cards-block.style-blocks .tag {
  color: #994700;
  border-color: #f70;
}

.cards-block.style-blocks .status {
  color: #f70;
}

.cards-block.style-blocks .indicator {
  color: #ce6000;
}

.cards-block.style-blocks .card .image {
  width: 80px;
  border: 1px solid #f70;
  border-radius: 10px;
  padding: 0;
  transition: all .2s ease-in-out;
}

.cards-block.style-blocks .card .image img {
  object-fit: cover;
}

.cards-block-button {
  justify-content: flex-end;
  padding: 40px 0 0;
  display: flex;
}

.cards-block-list {
  padding-top: 40px;
}

.cards-block-list .intro {
  padding-bottom: 3vw;
}

.cards-block-list .intro h2 {
  margin-top: 0;
}

.cards-block-list .cards {
  width: 100%;
}

.cards-block-list .backbutton {
  color: inherit;
  margin-bottom: 40px;
}

.cards-block-list.style-apps {
  color: #004666;
}

.cards-block-list.style-apps .backbutton {
  color: #fff;
}

.cards-block-list.style-extensions {
  color: #2a4b00;
  border: none;
}

.cards-block-list.style-extensions .backbutton {
  color: #5c9b0d;
}

.cards-block-list.style-blocks {
  color: #823d00;
}

.cards-block-list.style-blocks .backbutton {
  color: #f70;
}

.detail {
  padding: 40px 0;
  position: relative;
  overflow: hidden;
}

.detail .overlay {
  width: 100%;
  height: 100%;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.detail .background {
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: .4;
  background-image: url("map-bg-right.2cb9aade.svg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 60vw;
  position: absolute;
  top: 0;
  left: 0;
}

.detail .container {
  z-index: 1;
  gap: 20px;
  position: relative;
}

@media (max-width: 769px) {
  .detail .container {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .detail .container {
    gap: 40px;
  }
}

@media (min-width: 769px) {
  .detail .container .column-left {
    width: 20%;
    min-width: 300px;
  }
}

.detail .container .column-left .card-top {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (min-width: 769px) {
  .detail .container .column-left .card-top {
    padding-top: 50px;
  }
}

.detail .container .column-left .card-top .image img {
  width: 100%;
  max-width: 150px;
}

@media (max-width: 769px) {
  .detail .container .column-left .card-top .image img {
    max-width: 40vw;
  }
}

.detail .container .column-left .card-bottom {
  padding-top: 20px;
  font-size: 14px;
}

@media (min-width: 768px) {
  .detail .container .column-left .card-bottom {
    padding-top: 50px;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .detail .container .column-left .card-bottom dl {
    max-width: 45vw;
    margin: 0 auto;
  }
}

.detail .container .column-left .tag {
  margin-top: 30px;
  display: inline-flex;
}

.detail .container .column-left .button {
  margin-top: 30px;
}

.detail .container .column-left .status {
  margin: 20px 0 10px;
  font-size: 16px;
  font-weight: 600;
}

.detail .container .column-left h1 {
  color: #007bb3;
  margin-bottom: 0;
  font-size: 20px;
}

.detail .container .column-left p {
  margin-top: 0;
  font-weight: 600;
}

.detail .container .column-left h2 {
  color: #007bb3;
  margin: 0;
}

.detail .container .column-left dl {
  flex-wrap: wrap;
  display: flex;
}

.detail .container .column-left dl dd, .detail .container .column-left dl dt {
  width: 50%;
  margin: 0;
}

.detail .container .column-left dl dd {
  padding-right: 15px;
}

.detail .container .column-left dl dt {
  padding-left: 15px;
  font-weight: 600;
}

.detail .container .column-left .environment-error {
  color: red;
  padding: 5px 0;
  font-size: 13px;
  font-weight: 500;
}

.detail .container .column-right {
  flex: 1;
}

.detail .container .column-right h2 {
  color: #007bb3;
  margin: 0;
}

.detail .container .column-right .screenshots .slick-track > div {
  margin: 10px;
}

.detail .container .column-right .screenshots .slick-track > div img {
  display: block;
}

.detail .screenshots .slick-arrow {
  width: 40px;
  height: 40px;
  color: #fff;
  cursor: pointer;
  background: #007bb3;
  border-radius: 4px;
  font-size: 16px;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.detail .screenshots .slick-arrow.slick-disabled {
  opacity: 0;
  visibility: hidden;
  display: none;
}

.detail .screenshots .slick-prev {
  left: -30px;
  -webkit-transform: translateX(-50%)translateY(-50%);
  transform: translateX(-50%)translateY(-50%);
}

.detail .screenshots .slick-prev:before {
  content: "";
}

.detail .screenshots .slick-next {
  right: -30px;
  -webkit-transform: translateX(50%)translateY(-50%);
  transform: translateX(50%)translateY(-50%);
}

.detail .screenshots .slick-next:before {
  content: "";
}

.detail .screenshots .slick-track {
  align-items: center;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

.detail .card {
  padding: 30px;
}

.detail .backbutton {
  margin-bottom: 40px;
}

.detail.style-apps {
  color: #004666;
  background: linear-gradient(90deg, #009be1 0%, #004e71 100%);
}

.detail.style-apps .install-button {
  background-color: #007bb3;
}

.detail.style-apps .install-button:hover {
  background-color: #0097dc;
  border-color: #0097dc;
}

.detail.style-apps .update-button {
  background-color: #007bb3;
}

.detail.style-apps .update-button:hover {
  background-color: #0097dc;
  border-color: #0097dc;
}

.detail.style-apps .status {
  color: #007bb3;
}

.detail.style-apps .installed {
  cursor: auto;
  background-color: #2f8f37;
}

.detail.style-apps .tag {
  color: #007bb3;
  border-color: #007bb3;
}

.detail.style-apps .backbutton {
  color: #007bb3;
}

.detail.style-apps .screenshots .slick-arrow {
  background: #007bb3;
}

.detail.style-apps .screenshots .slick-arrow:hover {
  background: #0097dc;
}

.detail.style-apps .screenshots .slick-arrow:active {
  background: #005880;
  border-color: #005880;
}

.detail.style-apps .column-left h1, .detail.style-apps .column-left h2 {
  color: #007bb3;
}

.detail.style-apps .column-right h2 {
  color: #007bb3;
  margin: 0;
}

.detail.style-apps .backbutton {
  color: #fff;
}

.detail.style-extensions {
  color: #2a4b00;
  background: linear-gradient(90deg, #b5db85 0%, #dceec5 100%);
}

.detail.style-extensions .install-button {
  background-color: #5c9b0d;
}

.detail.style-extensions .install-button:hover {
  background-color: #72c110;
  border-color: #72c110;
}

.detail.style-extensions .update-button {
  background-color: #5c9b0d;
}

.detail.style-extensions .update-button:hover {
  background-color: #72c110;
  border-color: #72c110;
}

.detail.style-extensions .status {
  color: #5c9b0d;
}

.detail.style-extensions .installed {
  cursor: auto;
  background-color: #2f8f37;
}

.detail.style-extensions .tag {
  color: #5c9b0d;
  border-color: #5c9b0d;
}

.detail.style-extensions .backbutton {
  color: #5c9b0d;
}

.detail.style-extensions .screenshots .slick-arrow {
  background: #5c9b0d;
}

.detail.style-extensions .screenshots .slick-arrow:hover {
  background: #72c110;
}

.detail.style-extensions .screenshots .slick-arrow:active {
  background: #406c09;
  border-color: #406c09;
}

.detail.style-extensions .column-left h1, .detail.style-extensions .column-left h2 {
  color: #5c9b0d;
}

.detail.style-extensions .column-right h2 {
  color: #5c9b0d;
  margin: 0;
}

.detail.style-blocks {
  color: #823d00;
  background: linear-gradient(90deg, #feebce 0%, #ffb64b 100%);
}

.detail.style-blocks .install-button {
  background-color: #f70;
}

.detail.style-blocks .install-button:hover {
  background-color: #ff8d29;
  border-color: #ff8d29;
}

.detail.style-blocks .update-button {
  background-color: #f70;
}

.detail.style-blocks .update-button:hover {
  background-color: #ff8d29;
  border-color: #ff8d29;
}

.detail.style-blocks .status {
  color: #f70;
}

.detail.style-blocks .installed {
  cursor: auto;
  background-color: #2f8f37;
}

.detail.style-blocks .tag {
  color: #f70;
  border-color: #f70;
}

.detail.style-blocks .backbutton {
  color: #f70;
}

.detail.style-blocks .screenshots .slick-arrow {
  background: #f70;
}

.detail.style-blocks .screenshots .slick-arrow:hover {
  background: #ff8d29;
}

.detail.style-blocks .screenshots .slick-arrow:active {
  background: #cc5f00;
  border-color: #cc5f00;
}

.detail.style-blocks .column-left h1, .detail.style-blocks .column-left h2 {
  color: #f70;
}

.detail.style-blocks .column-right h2 {
  color: #f70;
  margin: 0;
}

.modal {
  z-index: 1001;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: open_sans, Arial, Helvetica, sans-serif;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop {
  width: 100%;
  height: 100%;
  background: rgba(161, 161, 161, .2);
  position: absolute;
  top: 0;
  right: 0;
}

.modal-window {
  width: 100%;
  height: auto;
  max-height: 80vh;
  max-width: 600px;
  border-radius: 8px;
  flex-direction: column;
  margin: 25px;
  display: flex;
  position: relative;
  box-shadow: 0 10px 10px rgba(0, 0, 0, .15);
}

.modal-title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}

.modal-header {
  color: #fff;
  height: 35px;
  -webkit-user-select: none;
  user-select: none;
  background: #007bb3;
  border-radius: 8px 8px 0 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  display: flex;
}

.modal-close {
  cursor: pointer;
  width: 35px;
  height: 35px;
  color: #fff;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  transition: all .2s ease-in-out;
  display: flex;
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}

.modal-close:hover {
  opacity: .7;
}

.modal-body {
  color: #004666;
  background: #fff;
  border-radius: 0 0 8px 8px;
  flex: 1;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.modal-body > p:first-child {
  margin-top: 0;
}

.modal-buttons {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding-top: 20px;
  display: flex;
}

.modal-buttons.align-center {
  justify-content: center;
}

.modal .indicator {
  position: unset;
  padding: 0;
}

.modal .indicator-inner {
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.modal .indicator-title {
  margin: 0;
}

.indicator {
  -webkit-user-select: none;
  user-select: none;
  opacity: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin: 0 280px;
  padding: 30px;
  -webkit-animation: .2s 10ms forwards show;
  animation: .2s 10ms forwards show;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.indicator.has-error .indicator-inner {
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.indicator.has-error .indicator-inner .indicator-title {
  order: 2;
  margin-bottom: 0;
}

.indicator-inner {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.indicator-title {
  margin: 0 0 15px;
  font-size: 1rem;
  font-weight: 600;
}

.indicator .loading-indicator {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0);
  border: 4px solid rgba(0, 123, 179, .2);
  border-bottom-color: #007bb3;
  border-radius: 50%;
  -webkit-animation: 2s linear infinite spin;
  animation: 2s linear infinite spin;
  position: relative;
}

.indicator .loading-error {
  opacity: .3;
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNi41LjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGFhZ18xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDU2MCA1NjAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2MCA1NjA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGQ9Ik0yODAsMEMyMDUuNywwLDEzNC41LDI5LjUsODIsODJDMjkuNSwxMzQuNSwwLDIwNS43LDAsMjgwczI5LjUsMTQ1LjUsODIsMTk4YzUyLjUsNTIuNSwxMjMuNyw4MiwxOTgsODJzMTQ1LjUtMjkuNSwxOTgtODINCgljNTIuNS01Mi41LDgyLTEyMy43LDgyLTE5OHMtMjkuNS0xNDUuNS04Mi0xOThDNDI1LjUsMjkuNSwzNTQuMywwLDI4MCwweiBNMzE1LDQyMGgtNzB2LTcwaDcwVjQyMHogTTMxNSwzMDMuM2gtNzBWMTQwaDcwVjMwMy4zeiINCgkvPg0KPC9zdmc+DQo=");
  background-repeat: no-repeat;
  background-size: contain;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.site-footer {
  color: #004666;
  background: #e0f5ff;
}

.site-footer-top {
  border-top: 12px solid #fff;
  padding: 5vw 0;
}

.site-footer-top .container {
  justify-content: center;
}

.site-footer-bottom {
  border-top: 1px solid rgba(0, 123, 179, .5);
  padding: 20px 0;
  font-size: 14px;
}

.site-footer-bottom .container {
  justify-content: space-between;
}

.site-footer-bottom .copyright {
  font-weight: 600;
}

.site-footer-bottom a {
  text-decoration: none;
}

.site-footer-bottom a:hover {
  color: #007bb3;
}

.site-footer-bottom a + a:before {
  content: "|";
  margin: 0 10px;
  text-decoration: none;
}

.site-footer h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
}

.site-footer p {
  margin-top: 0;
  margin-bottom: .7em;
}

.site-footer a {
  color: inherit;
  font-weight: 600;
}

.site-footer .container {
  align-items: center;
  gap: 5vw;
}

.site-footer img {
  max-width: 100%;
}

@media (max-width: 700px) {
  .site-footer .container {
    flex-direction: column;
  }

  .site-footer .column-left {
    order: 2;
  }

  .site-footer .column-right {
    order: 1;
  }

  .site-footer img {
    max-width: 70vw;
    margin: 0 auto;
  }
}

@media (min-width: 700px) {
  .site-footer .column-left {
    width: 40%;
    text-align: center;
  }

  .site-footer .column-left img {
    max-width: 70%;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  .site-footer .column-left {
    width: 20%;
  }

  .site-footer .column-left img {
    max-width: 100%;
    margin: 0 auto;
  }
}

.user {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.user .profileName {
  color: #004666;
  text-align: right;
  white-space: nowrap;
}

@media (max-width: 1024px) {
  .user .profileName {
    font-size: 13px;
  }
}

@media (max-width: 1200px) {
  .user .profileName {
    font-size: 14px;
  }
}

.user .profileImg {
  width: 48px;
  height: 48px;
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 100%;
  flex: none;
  overflow: hidden;
  box-shadow: 0 0 6px rgba(0, 0, 0, .16);
}

@media (max-width: 769px) {
  .user .profileImg {
    width: 42px;
    height: 42px;
  }
}

.user .profileImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user .currentUserWindow {
  opacity: 0;
  z-index: 1;
  color: #000;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .16);
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  font-weight: 400;
  line-height: normal;
  -webkit-animation: .2s 10ms forwards show;
  animation: .2s 10ms forwards show;
  display: flex;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .2);
}

.user .currentUserWindow:before, .user .currentUserWindow:after {
  pointer-events: none;
  content: " ";
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  right: 15px;
}

.user .currentUserWindow:after {
  border: 7px solid rgba(0, 0, 0, 0);
  border-bottom-color: #f9f9f9;
  top: -14px;
  right: 16px;
}

.user .currentUserWindow:before {
  border: 8px solid rgba(0, 0, 0, 0);
  border-bottom-color: #cdcdcd;
  top: -16px;
}

.user .currentUserWindow .top {
  width: 100%;
  background: #f9f9f9;
  border-bottom: 1px solid rgba(0, 0, 0, .16);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 15px;
  display: flex;
}

.user .currentUserWindow .user-info {
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  display: flex;
}

.user .currentUserWindow .user-name {
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
}

.user .currentUserWindow .user-email {
  white-space: nowrap;
  font-size: 11px;
}

.user .currentUserWindow .user-details {
  text-align: left;
  padding-right: 20px;
}

.user .currentUserWindow .user-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 2px solid #fff;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0 0 6px rgba(0, 0, 0, .16);
}

.user .currentUserWindow .user-links {
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  padding: 7px 0 0;
  display: flex;
}

.user .currentUserWindow .user-links button {
  cursor: pointer;
  color: #004666;
  white-space: nowrap;
  background: none;
  border: none;
  padding: 0;
  font-family: open_sans, Arial, Helvetica, sans-serif;
  font-size: 11px;
  transition: all .2s ease-in-out;
}

.user .currentUserWindow .user-links button:hover {
  color: #000;
  text-decoration: underline;
}

.user .currentUserWindow .bottom {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.user .currentUserWindow .bottom a.link {
  width: 100%;
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
  color: #004666;
  background: #fff;
  border: none;
  margin-bottom: 0;
  padding: 10px 20px;
  font-family: open_sans, Arial, Helvetica, sans-serif;
  font-size: 14px;
  text-decoration: none;
}

.user .currentUserWindow .bottom a.link:first-child {
  border-top: none;
  margin-top: 8px;
}

.user .currentUserWindow .bottom a.link:hover {
  background: #c1d3e3;
}

.user .currentUserWindow .bottom a.link.logout {
  background: #f9f9f9;
  border-top: 1px solid #d0d1d1;
  margin-top: 8px;
}

.user .currentUserWindow .bottom a.link.logout:hover {
  background: #c1d3e3;
}

/*# sourceMappingURL=index.f7b36fa4.css.map */
